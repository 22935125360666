import './home.css'
import { Header } from "../../components/Header"
import { Logo } from '../../components/Logo'
import { Input } from '../../components/Input'
import { MdPersonAddAlt1 } from 'react-icons/md'
//import { FiTrash2 } from 'react-icons/fi'
import { FaEye, FaWindowClose, FaMoneyBillAlt, FaSave, FaUserLock } from 'react-icons/fa'
import { useEffect, useState } from 'react'

import { db } from '../../services/firebaseConection'

import { addDoc, collection, onSnapshot, query, orderBy, doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'

export default function Home() {
    const [nome, setNome] = useState("");
    const [tel, setTel] = useState("");
    const [pago, setPago] = useState(false);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState("");

    const [cadastrar, setCadastrar] = useState(true);

    async function handleRegister(e) {
        e.preventDefault();
        toast.dismiss()

        const idToast = toast.loading("Enviando dados")

        if (nome.trim().length < 2) {
            toast.update(idToast, { render: "Nome deve ter ao menos 2 caracteres", type: "error", isLoading: false, autoClose: 2500 });
            return;
        }

        addDoc(collection(db, "mulheres"), {
            Nome: nome.toUpperCase(),
            Tel: tel.toUpperCase(),
            Pago: pago,
            dtInsert: new Date(),
            dtEdit: new Date(),
        })
            .then(() => { toast.success("Dados gravados com sucesso"); limpaCampos() })
            .catch((err) => { toast.error("Opss deu algum erro") })
    }

    async function handleSave(e) {
        e.preventDefault();
        toast.dismiss()

        const idToast = toast.loading("Enviando dados")

        if (nome.trim().length < 2) {
            toast.update(idToast, { render: "Nome deve ter ao menos 2 caracteres", type: "error", isLoading: false, autoClose: 2500 });
            return;
        }

        await updateDoc(doc(db, "mulheres", id), {
            Nome: nome.toUpperCase(),
            Tel: tel.toUpperCase(),
            Pago: pago,
            dtEdit: new Date(),
        })

            // await setDoc(doc(db, "mulheres", id), {
            //     Nome: nome.toUpperCase(),
            //     Tel: tel.toUpperCase(),
            //     Pago: pago,
            //     dtEdit: new Date(),
            // })
            .then(() => {
                //toast.success("Dados gravados com sucesso"); 
                toast.update(idToast, { render: "Dados gravados com sucesso", type: "success", isLoading: false, autoClose: 2500 });
                setEdit(false); limpaCampos()
            })
            .catch((err) => { toast.update(idToast, { render: "Opss deu erro", type: "error", isLoading: false, autoClose: 250 }); })
    }

    const [mulheres, setMulheres] = useState([]);

    useEffect(() => {
        const mulheresRef = collection(db, "mulheres");
        const queryRef = query(mulheresRef, orderBy("dtInsert", "desc"));

        onSnapshot(queryRef, (snapshot) => {
            let lista = [];

            snapshot.forEach((doc) => {
                lista.push({
                    id: doc.id,
                    nome: doc.data().Nome,
                    pago: doc.data().Pago
                })
            })
            setMulheres(lista);
        })
    }, [])

    function limpaCampos() {
        toast.dismiss()
        setNome("")
        setTel("")
        setPago(false)
        setCadastrar(true)
    }

    async function loadData(id) {
        toast.dismiss();
        const docRef = doc(db, "mulheres", id);
        await getDoc(docRef)
            .then((snapshot) => {
                if (snapshot.data() !== undefined) {
                    setNome(snapshot.data().Nome);
                    setTel(snapshot.data().Tel);
                    setPago(snapshot.data().Pago);
                    setId(id);
                    setEdit(true);
                    setCadastrar(false);
                }
            })
    }

    async function handleDeleteLink(id) {
        const docRef = doc(db, "mulheres", id);
        await deleteDoc(docRef);
    }

    return (
        <div className="admin-container">
            <Header />
            <Logo />

            {!cadastrar &&
                <>
                    <div className='head-form'>
                        <button type='button' className='btn-close' style={{ float: 'right' }} onClick={() => { setCadastrar(true); limpaCampos(); }}><FaWindowClose size={20} color="#FFF" /></button>
                    </div>
                    <form className="form" onSubmit={(!edit ? handleRegister : handleSave)}>
                        <hr />
                        <label className="label">Nome</label>
                        <Input type="text" placeholder="Nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                        <label className="label">Telefone</label>
                        <Input type="text" placeholder="(  )" value={tel} onChange={(e) => setTel(e.target.value)} />
                        <section className='groupcheck'>
                            <Input type="checkbox" placeholder="Digite a Url" value={1} checked={pago} onChange={() => setPago(!pago)} />
                            <label style={{ marginLeft: 10 }}>Pago</label>
                        </section>

                        {!edit &&
                            <footer className='footer'>
                                <button className='btn-register' type='button' onClick={() => { setCadastrar(true); limpaCampos(); }}><FaWindowClose size={24} color="#fff" /> Cancelar</button>
                                <button className='btn-register' type='submit'><FaUserLock size={24} color="#fff" /> Cadastrar</button>
                            </footer>

                        }
                        {edit &&
                            <footer className='footer'>
                                <button className='btn-register' type='button' onClick={() => { setCadastrar(true); limpaCampos(); }}><FaWindowClose size={24} color="#fff" /> Cancelar</button>
                                <button className='btn-register' type='submit'><FaSave size={24} color="#fff" /> Salvar Alteração</button>
                            </footer>
                        }
                        <hr />
                    </form>
                </>
            }
            {cadastrar &&
                <div className='form'>
                    <button className='btn-register' onClick={() => { setCadastrar(false); setEdit(false) }}>
                        <MdPersonAddAlt1 size={18} /> Cadastrar Nova
                    </button>
                </div>
            }
            <h2 className='title'>Mulheres Cadastradas</h2>
            {mulheres.map((item, index) => (
                <article key={index} className='list animate-pop' style={{ backgroundColor: '#FFF' }}>
                    <div>
                        <p className='list-text'>{item.nome}</p>
                        <p><FaMoneyBillAlt size={18} color={(item.pago ? 'green' : '#C0C0C0')} /></p>
                    </div>
                    <div>
                        <button className='btn-view' onClick={() => loadData(item.id)} >
                            <FaEye size={18} />
                        </button>
                    </div>
                </article>
            ))}
        </div>
    )
}