import './header.css';

import { BiLogOut } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai'
//import { SlUserFemale } from 'react-icons/sl'

import { auth } from '../../services/firebaseConection'
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';

export function Header() {
    async function handleLogOut() {
        await signOut(auth).then(() => { toast.error("Usuário saiu") })

    }

    return (
        <header className='admin-header'>
            <nav className='nav-header'>
                {/* <button onClick={handleLogOut}>
                    <BiLogOut size={28} color="#DB2629" />
                </button> */}
                <div>
                    <Link to="/" title='Home'><AiFillHome size={28} /></Link>
                </div>
                <div>
                    <button onClick={handleLogOut}>
                        <BiLogOut size={28} color="#DB2629" />
                    </button>
                </div>

            </nav>
        </header>
    )
}