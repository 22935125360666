import { Link } from 'react-router-dom'
import './logo.css'


export function Logo() {
    return (
        <Link to="/">
            <h1 className='logo'>Ministério<span className='logo-text'>Mulher</span></h1>
        </Link>
    )

}